<template>
  <consent-retry-failure v-if="showConsentRetryError" :source="credsSource" :close-action="redirect"></consent-retry-failure>
  <consent-failure v-else-if="consentFailure" :source="credsSource" :close-action="redirect"></consent-failure>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import DDLogs from '@/DDLogs';
import { getSourceConfigurationByAggregatorID } from '@/constants/sourcesConfiguration';
import Segment from '@/Segment';
import LoadingPopup from '@/base-components/onboarding/LoadingPopup';
import CredentialsApi from '@/api/CredentialsApi';
import ConsentFailure from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/ConsentFailure';
import ConsentRetryFailure
  from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/ConsentRetryFailure';
import { shouldTryAgainObk } from '@/base-components/credentials/credentials-retry-utils';
import CredentialsConnectionSuccess
  from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/CredentialsConnectionSuccess';
import ConnectInvalidCredsSuggestion
  from '@/pages/responsive-pages/non-authenticated/open-banking/ConnectInvalidCredsSuggestion';
import BrowserInfo from '@/mixins/BrowserInfo.vue';
import AggregatorApi from '../../../../api/AggregatorApi';
import router from '../../../../router';
import consts from '../../../../constants/onboarding-state';

export default {
  name: 'OpenBankingLandingPage',
  components: {
    ConsentRetryFailure,
    ConsentFailure,
  },
  mixins: [BrowserInfo],
  data() {
    return {
      consentFailure: false,
      partiallyAuthorized: false,
      source: null,
    };
  },
  beforeDestroy() {
    // When customer created consent but still has other invalid creds, we want to redirect it to credentials page and open suggestion popups
    // added this condition to make sue we dont close the popups
    if (!this.hasInvalidCreds) {
      this.closeModal();
    }
  },
  async created() {
    const { state, code } = this.$route.query;
    this.source = this.$route.params.source === 'discount-obk' ? 'discount' : this.$route.params.source;
    this.setUpdatedCredentialsSourceName(this.source);
    DDLogs.log('OpenBankingLandingPage created', { state, code, source: this.source });
    const extraParams = this.incognitoModeDetectionAmplitudeEvents ? await this.getBrowserParams() : {};
    Segment.trackUserGot('OBKLandingPageEntered', { source: this.source, ...extraParams });
    this.openLoadingPopup();
    if (!code || !state) {
      DDLogs.error('Failed applying consent, code or state missing', { code, state, url: window.location.href });
      this.displayError();
    } else {
      try {
        await this.handleAccessCode(code, state);
      } catch (error) {
        DDLogs.error('OBKLandingPageError error applying access code',
          { state, code, source: this.source, error: error.response.data });
        Segment.trackUserGot('OBKLandingPageError', { error });
        this.displayError();
      }
    }
  },
  computed: {
    ...mapState('genericError', ['ERROR_TYPES']),
    ...mapState('onboardingState', ['pageName', 'pageParams', 'product']),
    ...mapGetters('credentials', ['invalidCredentialsList']),
    ...mapGetters('featureFlags', ['incognitoModeDetectionAmplitudeEvents']),
    isOnboarding() {
      return this.pageName && this.pageName !== consts.ONBOARDING_PAGE.CASHFLOW;
    },
    credsSource() {
      return getSourceConfigurationByAggregatorID(this.source);
    },
    showConsentRetryError() {
      return this.consentFailure && !shouldTryAgainObk(this.credsSource.sourceName);
    },
    sourceType() {
      return this.credsSource?.type === 'bank' ? 'הבנק' : 'חברת האשראי';
    },
    hasInvalidCreds() {
      return this.invalidCredentialsList.length;
    },
  },
  methods: {
    ...mapActions('credentials', ['fetchLoadingCredentialsState', 'fetchCredentials']),
    ...mapMutations('credentials', ['setUpdatedCredentialsSourceName', 'setConnectedConsentMetadata']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapMutations('genericError', ['setErrorType']),
    ...mapMutations('onboardingState', ['setPageParams']),
    ...mapActions('onboardingState', ['fetchOnboardingState', 'creditCardDetailsSubmitOpenBanking',
      'bankDetailsOpenBankingPartiallyAuthorized', 'bankDetailsSubmitOpenBanking']),
    displayError() {
      this.consentFailure = true;
      this.closeModal();
    },
    openLoadingPopup() {
      this.openModal({
        component: LoadingPopup,
        popupAlignment: 'center',
        props: {
          title: 'עוד רגע נמשיך...',
          subtitle: `המערכת מתחברת אל ${this.sourceType}`,
        },
      });
    },
    async handleAccessCode(accessCode, consentId) {
      const { consentStatus, metaData } = await AggregatorApi.applyAccessCode(consentId, this.source, accessCode);
      const { isOnboarding } = this;
      if (!consentStatus) {
        this.handleInvalidResponse(consentStatus);
        return;
      }
      Segment.trackUserGot('OBKLandingPageSuccess', { isOnboarding, consentStatus, source: this.source });
      DDLogs.log('OBKLandingPageSuccess apply access code success', { consentStatus });
      // We want that the sourceName in "createOBKConnectionSuccessEvent" will be identical to sourceName in "createOBKConnectionRequestEvent",
      // in order to alert when to many customers don't complete the connection process.
      // We should use the "source Id" from source configuration, but We receive the connected source name from the url which the source navigates to.
      // All source names from url match the source Id from the configuration file except Amex, so that is the reason from this condition

      await CredentialsApi.createOBKConnectionSuccessEvent({
        consentId,
        sourceName: this.credsSource.sourceName,
        browserInfo: await this.getBrowserParams(),
      });
      await this.fetchCredentials();

      if (this.hasInvalidCreds) {
        this.handleConsentSuccessWhenOtherSourcesAreInvalid();
        return;
      }
      if (isOnboarding) {
        await this.onboardingRedirect(consentStatus, metaData.credentialsId, this.credsSource.sourceName);
      } else {
        this.setConnectedConsentMetadata(metaData);
        await this.redirectToLoadingCredentialsPage();
      }
      await this.fetchLoadingCredentialsState();
    },
    // In case the customer has several invalid creds, the scrape wont run after consent validation, so we want to make sure
    // the customer connects all its invalid creds.
    // In order to do so, we move the customer to success page and afterwards to a page which leads to connection of the other invalid creds
    handleConsentSuccessWhenOtherSourcesAreInvalid() {
      this.redirectToCredentialsPage();
      this.openModal({
        component: CredentialsConnectionSuccess,
        props: {
          sourceType: this.credsSource.type,
          sourceName: this.credsSource.sourceName,
          closeAction: () => {
            this.openModal({
              component: ConnectInvalidCredsSuggestion,
              props: {
                closeAction: this.closeAction,
                source: this.credsSource,
              },
              popupAlignment: 'full-screen',
            });
          },
        },
        popupAlignment: 'full-screen',
      });
    },
    async redirect() {
      const { sourceName } = this.credsSource;
      if (this.isOnboarding) {
        await this.setPageParams({ ...this.pageParams, sourceName });
        this.redirectToNewOnboarding();
      } else {
        this.redirectToCredentialsPage();
      }
    },
    handleInvalidResponse(consentStatus) {
      DDLogs.error('Received empty consentStatus', { isOnboarding: this.isOnboarding, consentStatus });
      Segment.trackUserGot('OBKLandingPageErrorEmptyConsentStatus', { isOnboarding: this.isOnboarding, errorType: 'invalid-response' });
      this.displayError();
    },
    async onboardingRedirect(consentStatus, credentialsId, sourceName) {
      DDLogs.log('onboardingRedirects', { consentStatus, credentialsId, sourceName });
      try {
        if (consentStatus === 'partiallyAuthorised') {
          Segment.trackUserGot('OBKLandingPagePartiallyAuthorised', { sourceName });
          await this.bankDetailsOpenBankingPartiallyAuthorized();
        } else if (this.pageName === consts.ONBOARDING_PAGE.BANK_DETAILS) {
          await this.bankDetailsSubmitOpenBanking({ credentialsId, sourceName });
        } else {
          await this.creditCardDetailsSubmitOpenBanking({ credentialsId, sourceName });
        }
      } catch (error) {
        DDLogs.log('Failed submitting OB page, redirecting without it', { error });
      }
      await this.fetchOnboardingState();
      this.redirectToNewOnboarding();
    },
    redirectToNewOnboarding() {
      if (this.product === 'oneTimeScrapeIl') {
        router.push({ path: '/ots-ob' });
      } else {
        router.push({ path: '/ob' });
      }
    },
    redirectToCredentialsPage() {
      router.push({ path: '/credentials' });
    },
    redirectToLoadingCredentialsPage() {
      router.push({ path: '/loading-credentials' });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../scss/mixins';
</style>
